import React, { useState } from 'react';
import { TextField, Container } from '@mui/material';

const AddressForm = ({onChange, initAddress}) => {
  const [address, setAddress] = useState({
    street: initAddress.street || '',
    street2: initAddress.street2 ||'',
    city: initAddress.city || '',
    state: initAddress.state || '',
    postalCode: initAddress.postalCode || '',
    country: initAddress.country || '',
    district:  initAddress.district || '',
    region: initAddress.region || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });
    onChange({
        ...address,
        [name]: value,
      })
  };

  return (
    <Container maxWidth="xl">
      <TextField
        fullWidth
        margin="normal"
        label="Street"
        name="street"
        value={address.street}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Street 2"
        name="street2"
        value={address.street2}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="City"
        name="city"
        value={address.city}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="State"
        name="state"
        value={address.state}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Postal Code"
        name="postalCode"
        value={address.postalCode}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Country"
        name="country"
        value={address.country}
        onChange={handleChange}
      />
     
    </Container>
  );
};

export default AddressForm;
