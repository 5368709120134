const Clickable = ({ children, url}) => {

    const handleClick = () => {
        window.parent.postMessage({
            showModal: true,
            // modalUrl: `${config.apiUrl}/#/widget/${ean}/proofpoint/${pid}`
            modalUrl: url
        }, '*');
    }
    return (
        <div onClick={handleClick} className="clickable-item">
            {children}
        </div>
    );
}
export default Clickable;