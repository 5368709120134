


const Brand = ({slug}) => {

    return (
        <div>
            <h1>Brand</h1>
            <h2>{slug}</h2>
        </div>
    )
}


export default Brand;
