export const config = {
    
       apiUrl: 'https://ecocheck-a58or.ondigitalocean.app/api',
       //apiUrl: 'http://localhost:4000',
       publicApiUrl: 'https://ecocheck-a58or.ondigitalocean.app/api/public',

      //modalUrl: 'http://localhost:3001/product',
      modalUrl: 'http://localhost:54661/product'

      // apiUrl: 'http://localhost:4000',
      // publicApiUrl: 'http://localhost:4000/public'

} 