import { List, ListItem, ListItemText, Typography } from '@mui/material';

const CompanySummary = ({ brand }) => {
    return (
        <div>
           
            <List>
                <ListItem>
                    <ListItemText primary="Brand Name" secondary={brand.name} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Description" secondary={brand.description} />
                </ListItem>
                <List>
                    <ListItemText primary="Street" secondary={brand.address.street} />
                    <ListItemText primary="Street 2" secondary={brand.address.street2} />
                    <ListItemText primary="City" secondary={brand.address.city} />
                    <ListItemText primary="State" secondary={brand.address.state} />
                    <ListItemText primary="Postal Code" secondary={brand.address.postalCode} />
                    <ListItemText primary="Country" secondary={brand.address.country} />
                </List>
                <List>
                    <ListItemText primary="Street" secondary={brand.billing?.street} />
                    <ListItemText primary="Street 2" secondary={brand.billing?.street2} />
                    <ListItemText primary="City" secondary={brand.billing?.city} />
                    <ListItemText primary="State" secondary={brand.billing?.state} />
                    <ListItemText primary="Postal Code" secondary={brand.billing?.postalCode} />
                    <ListItemText primary="Country" secondary={brand.billing?.country} />
                </List>
            </List>
        </div>
    )
}
export default CompanySummary;