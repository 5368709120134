import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Routes, BrowserRouter, Route, Outlet, Link, HashRouter } from "react-router-dom";
import Widget from './pages/widgets/Widget';
import Modal from './pages/modals/Modal';
import Brand from './pages/Brand';
import RegisterPage from './register/pages/register/RegisterPage';
import { ThemeProvider, createTheme} from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));


const theme = createTheme({

  palette: {
    primary: {
      //main: '#90dFa0', // A balanced, vibrant green
      main: '#002B10', // A balanced, vibrant green
      light: '#80E27E', // Lighter shade of the primary color
      dark: '#087F23' // Darker shade of the primary color
    },
    secondary: {
      main: '#A8D5BA', // A muted, professional green
      light: '#C2E6D3', // Lighter shade of the secondary color
      dark: '#85B395' // Darker shade of the secondary color
    },
    background: {
      default: '#F4F9F4', // A very light greenish tint for backgrounds
      paper: '#fafafa' // Slightly darker tint for paper elements

    }
  },
  components: {


    MuiTextField: {
      styleOverrides: {
        '&:hover fieldset': {
          borderColor: '#5c6ac4', // Die Grenzfarbe beim Hovern
        },
      }
    },
   
   
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          // Override styles for the input root here
          // For example, to change the input height:
          '&[class*="MuiOutlinedInput-root"]': {
            padding: '0em',
            paddingLeft: '0.25em',
            margin: '0em',
            // Set the height you want for the input field
            height: 'auto', // Use 'auto' or set a specific height
            '& .MuiAutocomplete-input': {
              height: 'auto', // Same here for the actual input element
              // Adjust padding, font size, or other properties as needed
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          position: 'absolute',
          left: 8, // Adjust this value based on your design needs
          marginRight: '8px', // Ensures the icon doesn't affect the label positioning
        },
        root: {
          // Ensure the label remains centered and doesn't shift due to the absolute positioning of the startIcon
          justifyContent: 'center',
          paddingLeft: '16px', // Adjust the left padding to ensure content is centered
          position: 'relative', // Needed to position the startIcon absolutely
          fontSize: '0.825em',
        },
      },
    },

  },
});

root.render(
  <React.StrictMode>
    <HashRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/product/:ean" element={<Widget />} />
          <Route path="/product/:ean/modal" element={<Modal />} />
          <Route path="/brand" element={<Brand />} />
          <Route path="/brand/:slug" element={<Brand slug={MediaQueryList.slug} />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/register/:slug" element={<RegisterPage slug={MediaQueryList.slug} />} />
        </Routes>
      </ThemeProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
