import React, { useState } from 'react';
import { TextField, Container, Select, MenuItem, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio } from '@mui/material';
import { Option, OptionGroup } from '@mui/base';

const EmailPasswordForm = ({contact, onChange }) => {


    const handleChange = (e) => {
        const { name, value } = e.target;
   
        onChange({
            ...contact,
            [name]: value,
        })
    };

    return (
        <Container maxWidth="xl">
            <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                required
                value={contact.email}
                onChange={handleChange}
            />
            <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                value={contact.email}
                onChange={handleChange}
            />
            <TextField
                fullWidth
                margin="normal"
                label="Password"
                type='password'
                name="password"
                value={contact.password}
                onChange={handleChange}
            />
        </Container>
    );
};

export default EmailPasswordForm;
