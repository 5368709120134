import './App.css';
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import React from 'react';
import './i18n';
import Modal from './pages/modals/Modal';
import { ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from 'react-responsive';


import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ['halisr-book', 'serif'].join(','),
    fontSize: '13.4', // Added missing comma here
    lineHeight: 1.6,
    button: {
      fontFamily: ['halisr-book', 'serif'].join(','),
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.6,
      letterSpacing: '0.02857em',

    }
  },
  overrides: {
    MuiBox: {
      root: {
        padding: "10px",
        fontSize: "1em",
      }
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: "5px"
      },
      primary: {
        fontSize: '1rem',
      },
      secondary: {
        fontSize: '0.8rem',
      }
    },
  }

});

function App() {
  const [searchParams] = useSearchParams();
  const ean = searchParams.get("ean"); // "text"    


  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Modal passedEan={ean} />
      </ThemeProvider></React.StrictMode>
  );
}

export default App;
