import React, { useEffect, useState } from 'react';
import { TextField, Button, Switch, FormControlLabel, Container, Typography, FormGroup, Box, Checkbox, Select, Menu, MenuItem, Stepper, StepLabel, Step, StepButton, MobileStepper, LinearProgress } from '@mui/material';
import AddressForm from './AddressForm';
import ContactForm from './ContactForm';
import EmailPasswordForm from './EmailPasswordForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CompanySummary from './CompanySummary';
import ChoosePlanForm from './ChoosePlanForm';
import { useParams } from 'react-router-dom';
const BrandRegisterForm = () => {

    const { slug } = useParams()

    const [step, setStep] = useState(null);

    const [brand, setBrand] = useState({
        name: '',
        description: '',
        contact: '', // Assuming a simple string for demo, but could be a detailed object
        address: '', // Assuming a simple string for demo, but could be a detailed object
        imageUrl: '',
        online: false,
        adminEmail: '',
        adminPassword: '', // Reminder: Ensure secure handling of passwords in your actual implementation
        billing_equals_contact: true,
    });

    useEffect(() => {
        if (brand.name === '') {
            const storedCompany = JSON.parse( localStorage.getItem('company') );
            console.log(storedCompany)
            const data = storedCompany;
            if (data) {
                setBrand(data)
                setStep(data.step || 0)
            }
        }
    }, [])

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setBrand({
            ...brand,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        if (brand.billing_equals_contact) {
            setBrand({ ...brand, billing: brand.address, step: step + 1 })
        }



        localStorage.setItem('company', JSON.stringify(brand));

        setStep(step + 1)
        // Here you would typically send the data to your server via an API
    };

    const steps = ['Start', 'Address', 'Billing', 'Admin Account', 'Subscription', 'Payment'];


    return (
        <Container maxWidth="sm">
            {slug}
            <Box flex={1} justifyContent="space-between" alignItems="center" display="flex" flexDirection="row" sx={{ mb: 4, mt: 4 }}>
                <img src={'ecocheck_small.png'} alt="Eco" />
                <>
                    <Typography variant="h6" component="h4" gutterBottom>
                        Registration
                    </Typography>
                    <Typography variant="subtitle1" component="p" gutterBottom>
                        Step {step + 1} of {steps.length}
                    </Typography>
                </>
            </Box>

            <Box>
                <Button variant='text' onClick={() => setStep(step - 1)} disabled={step < 1} startIcon={<ArrowBackIcon />} >Back</Button>
                <LinearProgress variant="determinate" value={step * 100 / 6} />
            </Box>

            {1 == 2 &&
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {step > 0 &&
                        <Button variant='text' onClick={() => setStep(step - 1)} disabled={step < 1} startIcon={<ArrowBackIcon />}>
                            Back
                        </Button>
                    }
                </Box>
            }
            <p></p>
            <form onSubmit={handleSubmit}>
                {step === 0 &&
                    <>
                        <Typography variant="h6" component="h3" gutterBottom>
                            Company Information
                        </Typography>
                        <p>Please provide us with the Company Name & Description</p>

                        {/* Brand fields */}
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Brand Name"
                            name="name"
                            value={brand.name}
                            onChange={handleChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Description"
                            name="description"
                            multiline
                            rows={4}
                            value={brand.description}
                            onChange={handleChange}
                        />
                    </>}
                {step === 1 &&
                    <>
                        <Typography variant="h6" component="h3" gutterBottom>
                            Company Contact Information
                        </Typography>
                        <p>Please enter your company's address</p>

                        {1 == 2 && <ContactForm onChange={(e) => setBrand({ ...brand, contact: e })} />}
                        <AddressForm initAddress={brand.address} onChange={(e) => setBrand({ ...brand, address: e })} />
                    </>
                }
                {step === 2 &&
                    <>
                        <Typography variant="h6" component="h3" gutterBottom>
                            Billing Information
                        </Typography>
                        <p>Please enter the billing information if it differs from the contact address previously entered.</p>

                        <FormControlLabel control={
                            <Checkbox checked={brand.billing_equals_contact} onChange={(e) => {
                                setBrand({ ...brand, billing_equals_contact: e.target.checked })

                            }
                            } />
                        } label="Billing address same as contact address" />
                        {!brand.billing_equals_contact &&
                            <AddressForm initAddress={brand.billing} onChange={(e) => setBrand({ ...brand, billing: e })} />
                        }
                    </>
                }
                {step === 3 &&
                    <>
                        <Typography variant="h6" component="h3">
                            Admin User Information
                        </Typography>
                        <EmailPasswordForm onChange={(e) => setBrand({ ...brand, adminEmailPassword: e })} contact={brand.adminEmailPassword} />

                    </>
                }
                {step === 4 &&
                    <>
                        <Typography variant="h6" component="h3">
                            Company Summary
                        </Typography>
                        <p>
                            Please review the company information you have provided.
                        </p>
                        <CompanySummary brand={brand} />
                    </>
                }

                {step === 5 &&
                    <ChoosePlanForm company={brand} setCompany={setBrand} />
                }

                <Box sx={{ position: 'sticky', bottom: 0 }}>
                    <Button type="submit" variant="contained" maxWidth="xl" fullWidth sx={{ mt: 3 }}>
                        Continue
                    </Button>
                </Box>

            </form>

            <pre>
                {JSON.stringify(brand, null, 2)}
            </pre>

        </Container>
    );
};

export default BrandRegisterForm;
