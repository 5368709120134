import { useState, useEffect, useRef } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { config } from "../../config";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Avatar, Badge, Box, Button, ButtonBase, Card, Chip, Dialog, DialogActions, DialogContent, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Toolbar, Typography, colors, hexToRgb } from "@mui/material"
import DownloadIcon from '@mui/icons-material/Download';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { useMediaQuery } from 'react-responsive';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Leaf from './leaf.svg';
import LeafEmpty from './leaf_empty.svg';

const Modal = ({ passedEan }) => {



    const isDesktop = useMediaQuery({ minWidth: 632 });
    const isMobile = useMediaQuery({ maxWidth: 631 });



    const { ean } = useParams()

    const usingEan = passedEan || ean

    const [searchParams] = useSearchParams();

    const template = searchParams.get("template"); // "text"    
    const primaryColor = searchParams.get("primarycolor") || '000'  // "text"
    const secondaryColor = searchParams.get("secondarycolor") || 'fff'; // "text"
    const borderRadius = searchParams.get("borderradius"); // "text"


    const [pid, setPid] = useState(null); // "text"

    const [focusGroup, setFocusGroup] = useState(null); // "text"

    const [proofPoints, setProofPoints] = useState([]); // Make sure you have initialized the user state
    const [filteredProofPoints, setFilteredProofPoints] = useState([]); // Make sure you have initialized the user state
    const [product, setProduct] = useState({}); // Make sure you have initialized the user state
    const { apiUrl } = config

    const [grouped, setGrouped] = useState([]); // Make sure you have initialized the user state
    const { t, i18n } = useTranslation()

    const [filterGroup, setFilterGroup] = useState(-1); // Make sure you have initialized the user state

    const groups = ['climate', 'nature', 'waste', 'social', 'security'];

    const [groupList, setGroupList] = useState([]); // Make sure you have initialized the user state

    const [error, setError] = useState(null); // Make sure you have initialized the user state

    const [language, setLanguage] = useState(null); // Make sure you have initialized the user state

    const [value, setValue] = useState('all');

    const accordionRefs = useRef({});

    const scrollToAccordionById = (id) => {

        const offset = 128; // Adjust as needed
        const element = accordionRefs.current[id]

        if (!element) return;

        element.scrollIntoView({ behavior: 'auto', block: 'start', alignToTop: true });

        const scrollYAdjustment = window.scrollY - offset; // Calculate the adjusted scroll position
        if (window.scrollY && offset) {
            window.scrollTo({
                top: scrollYAdjustment,
                behavior: 'smooth'
            });
        }

    };

    useEffect(() => {
        if (pid) {
            if (value != 'all') {
                setValue(proofPoints.find(pp => pp.code === pid)?.group);
            } else {

            }


            scrollToAccordionById(pid); // Adjust the ID as needed
        }
    }, [pid]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (language) {
            changeLanguage(language)
        }
    }, [language]);



    const fetchProofPoints = async () => {
        try {
            //const res = await fetch(`${apiUrl}/badges/ean/${usingEan}`);
            const res = await fetch(`${apiUrl}/badges/channel/43432423432432/ean/${usingEan}`);
            const data = await res.json();
            if (!data.error) {
                setProduct(data)
                setProofPoints(data.badges.sort((a, b) => (a.group > b.group) ? 1 : -1));
            } else {
                setError(data.error);
            }

        } catch (err) {
            setProofPoints([]);
            console.error("error while loading proof points: ", err);
            setError(err.message);
        }
    }

    useEffect(() => {
        fetchProofPoints();
    }, []);


    useEffect(() => {
        const groupedAndCounted = proofPoints.reduce((acc, item) => {
            // Assuming you want to group by the 'type' property
            const key = item.group;
            if (!acc[key]) {
                acc[key] = 0;
            }
            acc[key] += 1;
            return acc;
        }, {});
        setGrouped(groupedAndCounted)

        setGroupList({
            'climate': proofPoints.filter(pp => pp.group == 'climate'),
            'nature': proofPoints.filter(pp => pp.group == 'nature'),
            'waste': proofPoints.filter(pp => pp.group == 'waste'),
            'social': proofPoints.filter(pp => pp.group == 'social'),
            'security': proofPoints.filter(pp => pp.group == 'security'),
            'all': proofPoints
        })



    }, [proofPoints]);

    useEffect(() => {


        if (filterGroup > -1) {
            setFilteredProofPoints(proofPoints.filter(pp => pp.group === groups[filterGroup]))
        } else {
            setFilteredProofPoints(proofPoints)
        }

    }, [filterGroup, proofPoints]);

    const tabBadge = (groupCode, count) => {
        return (
            <div style={{ backgroundColor: t('groups.' + groupCode + '.color'), marginTop: '-8px', marginLeft: '8px', marginBottom: '8px', marginRight: '0px', padding: '4px 8px 4px 8px', borderRadius: '50%' }}>
                {count}
            </div>
        )
    }
    const renderTab = (groupCode) => {

        if (groupCode == 'all') return (
            <Tab key={groupCode} value={groupCode}
                label={<div style={{ display: 'inline-flex', padding: '0px', fontSize: 'smaller' }}>{t('groups.' + groupCode + '.label')} {tabBadge(groupCode, proofPoints.length)}</div>}
            />
        )
        {

            if (grouped[groupCode] > 0) return (
                <Tab key={groupCode} value={groupCode}
                    label={<div style={{ display: 'inline-flex', padding: '0px', fontSize: 'smaller' }}>{t('groups.' + groupCode + '.label')} {tabBadge(groupCode, grouped[groupCode])}</div>}
                />
            )
        }
        return null
    }

    const renderChip = (groupCode) => {


        const borderString = groupCode === value ? '1px solid #00000050' : '1px solid #fff';

        if (groupCode == 'all') return (
            <ButtonBase key={groupCode} value={groupCode} sx={{ backgroundColor: t('groups.' + groupCode + '.color'), p: 0, pl: 1, pr: 1, borderRadius: 10, m: 0.1, boxShadow: '1px 1px #00000020', fontFamily: 'halisr-book', border: borderString }}
                onClick={() => { setValue(groupCode) }}>
                <Box sx={{ fontSize: 'smaller' }}>{t('groups.' + groupCode + '.label')}</Box>
            </ButtonBase>
        )

        if (grouped[groupCode] > 0) return (
            <ButtonBase key={groupCode} value={groupCode} sx={{ backgroundColor: t('groups.' + groupCode + '.color'), p: 0.3, pl: 1, pr: 1, borderRadius: 10, m: 0.1, boxShadow: '1px 1px #00000020', fontFamily: 'halisr-book', border: borderString }}
                onClick={() => { setValue(groupCode) }}>
                <Box sx={{ fontSize: 'smaller' }}>{t('groups.' + groupCode + '.label')}</Box>
            </ButtonBase>
        )

        return null
    }

    const downloadProofFile = (fileUrl) => {
        if (fileUrl) window.open(fileUrl, '_blank');
        else console.error("No file url found for proof point");

    }
    const renderTabPanel = (groupCode) => {
        return (
            <>

                <TabPanel value={groupCode} sx={{ p: 0 }}>
                    <List sx={{ p: 0 }}>
                        {groupList[groupCode]?.map((proofPoint, index) => (

                            <Accordion expanded={proofPoint.code == pid} onClick={() => { if (proofPoint.code == pid) { setPid(null) } else { setPid(proofPoint.code) } }} disableGutters variant='elevation' sx={{ backgroundColor: t('groups.' + proofPoint.group + '.color') }}
                                key={proofPoint.code} ref={(el) => (accordionRefs.current[proofPoint.code] = el)}>

                                <AccordionSummary >

                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
                                        <img src={proofPoint?.image_url} alt={proofPoint.name} className="proofpoint-image" />
                                        <ListItemText primary={<span>{t('proof_points.' + proofPoint.code + '.label')}</span>} secondary={t('proof_points.' + proofPoint.code + '.info')} />

                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{ paddingLeft: 2, marginTop: 0, paddingTop: 0, border: '0px solid #000' }}>
                                    <Box sx={{ marginLeft: '84px' }}>
                                        <div className="details">

                                            <Card sx={{ pl: 2, pr: 2, mb: 1 }}>
                                                <span>{t('modal.about')} '{t('proof_points.' + proofPoint.code + '.label')}'?</span>
                                                <Box sx={{ m: 2 }} className={'modal-text'}>
                                                    <span>{t('proof_points.' + proofPoint.code + '.description')}
                                                    </span></Box>


                                            </Card>
                                            <Card sx={{ pl: 2, pr: 2, mb: 1 }}>
                                                <span>{t('modal.requirement')}:</span>


                                                <Box sx={{ m: 2 }} className={'modal-text'}>
                                                    <span>{t('proof_points.' + proofPoint.code + '.criteria')}</span>
                                                </Box>
                                            </Card>

                                            {proofPoint.documents?.length >= 0 ?
                                                <>
                                                    <List>
                                                        <ListItemButton onClick={() => { downloadProofFile(proofPoint.documents[0].url) }}>
                                                            <ListItemAvatar><SaveAltIcon /></ListItemAvatar>

                                                            <ListItemText primary={<Box className={'modal-text'}>{t('modal.download_link')}</Box>} />
                                                        </ListItemButton>
                                                    </List>
                                                </> : <>
                                                    <List>
                                                        <ListItem onClick={() => { downloadProofFile(proofPoint.fileUrl) }}>
                                                        </ListItem>
                                                    </List>
                                                </>}


                                        </div>
                                    </Box>
                                </AccordionDetails>

                            </Accordion>))}
                    </List>
                </TabPanel>
            </>



        )
    }

    const sendCloseMessage = () => {
        window.parent.postMessage({
            hideModal: true
        }, '*'); // Replace '*' with your parent page origin for security
    }


    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.pid) {
                setPid(event.data.pid);
            }
            if (event.data.lang) {

                setLanguage(event.data.lang);
            }
        };

        // Add event listener for incoming messages
        window.addEventListener('message', handleMessage);

        // Cleanup the event listener on component unmount
        //return () => window.removeEventListener('message', handleMessage);
    }, []);

    useEffect(() => {
        if (pid) {
            const proofPoint = proofPoints.find(pp => pp.code === pid);
            if (proofPoint) {
                setFocusGroup(proofPoint.group);

                //     setFilterGroup(groups.indexOf(proofPoint.group));
            }
        }
    }, [pid, proofPoints]);



    const RenderOverview = () => {
        return (
            <>
                <Box className="dialog" sx={{mb:10}}>
                    <AppBar position="static" color="default" variant='elevation' sx={{ mb: 1 }}>
                        <Toolbar >

                            <Box>

                                <img src={Leaf} alt="React Logo" height="20" />
                                <img src={Leaf} alt="React Logo" height="20" />
                                <img src={Leaf} alt="React Logo" height="20" />

                            </Box>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, textAlign: 'center', display: { xs: 'block', sm: 'block' }}}
                            >Produkt ECO Level 3</Typography>
                            <ButtonBase sx={{ padding: 0.5, borderRadius: 2 }} variant="text" className="head-close" onClick={sendCloseMessage}><CloseIcon /></ButtonBase>

                        </Toolbar>
                    </AppBar>



                    <Box sx={{ p: 1,  backgroundColor: '#fff',  height: 'calc(100% - 40px)',overflowY: 'scroll' }}>

                            {1 == 2 &&
                                                    <Card sx={{ p: 2, m: 1, bgcolor: '#f0f0f0', boxShadow: '0px 0px 0px' }}>

                                <Box sx={{ pl: 1, pr: 1 }}>

                                    Für <b>étalon vert</b> steht Umwelt- und Verantwortungsbewusstsein an erster Stelle. <br />

                                    Die folgenden Nachhaltigkeits-, Sicherheits- und Sozialmerkmale dieses Produktes wurden unabhängig durch  <b><span style={{ color: "#007700", fontWeight: '800', fontSize: 'smaller' }}>ECO</span><span style={{ color: "#404040", fontWeight: '800', fontSize: 'smaller' }}>CHECK</span></b>  bestätigt und werden regelmäßig auf ihre Aktualität und Authentizität überprüft.
                                </Box>
                                </Card>        
                            }

                                      <List sx={{}}>
                            {proofPoints.map((proofPoint, index) => (
                                <ListItemButton onClick={() => { setPid(proofPoint.code) }} key={proofPoint.code} ref={(el) => (accordionRefs.current[proofPoint.code] = el)}>
                                    <ListItemAvatar sx={{ mr: 1 }}><img src={proofPoint.image_url} className="proofpoint-image" /></ListItemAvatar>
                                    <ListItemText primary={t('proof_points.' + proofPoint.code + '.label')} secondary={t('proof_points.' + proofPoint.code + '.info')} />

                                </ListItemButton>
                            ))}
                        </List>
                    </Box>

                </Box>
                <Box className="mobile-footer">
                    <span>
                        {t('modal.verified_by')}</span><img src={'ecocheck_small.png'} />
                </Box>
            </>
        )
    }

    const RenderDetail = ({ proofPoint }) => {


        if (proofPoint == undefined) return <RenderOverview />;
        return (
            <>


                <Box className="dialog">
                    <AppBar position="static" color="default" variant='elevation' sx={{ mb: 1 }} >
                        <Toolbar>

                            <ButtonBase sx={{ float: 'left', padding: 0.5, borderRadius: 2 }} variant="text" className="head-close" onClick={() => setPid(null)} sx={{ mr: 2 }}><ArrowBackIcon /></ButtonBase>



                            <img src={proofPoint?.image_url} alt={proofPoint?.code} className="proofpoint-image" />

                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' }, m: 1 }}
                            >{t('proof_points.' + proofPoint?.code + '.label')}</Typography>

                            <ButtonBase sx={{ float: 'left', padding: 0.5, borderRadius: 2 }} variant="text" className="head-close" onClick={sendCloseMessage}><CloseIcon /></ButtonBase>

                        </Toolbar>
                    </AppBar>

                    <Box sx={{ p: 1, height: '100%', backgroundColor: '#fff', overflowY: 'scroll', mb: 5 }}>

                        <List>

                            <Box sx={{ p: 1, mb: 1, textAlign: 'center' }}>
                                <Box sx={{ flex: 1, flexDirection: 'row', display: 'inline-flex', justifyContent: 'space-between' }} className={'modal-text'}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ flex: 0.1 }}>
                                                <img src={product?.image_url} alt={product?.name} width="40" /><br />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={t('proof_points.' + proofPoint.code + '.info')} />
                                    </ListItem>



                                </Box>
                            </Box>

                            <Card sx={{ p: 1, mb: 2, bgcolor: '#a0a0a020' }}>
                                <Typography variant='subtitle1' fontWeight={'bold'} sx={{ mb: 1 }}>{t('modal.about')} '{t('proof_points.' + proofPoint.code + '.label')}'?</Typography>

                                <Typography variant='subtitle2'>{t('proof_points.' + proofPoint.code + '.description')}</Typography>


                            </Card>
                            <Card sx={{ p: 1, mb: 2, bgcolor: '#a0a0a020' }}>
                                <Typography variant='subtitle1' fontWeight={'bold'} sx={{ mb: 1 }}>{t('modal.requirement')}:</Typography>


                                <Typography variant='subtitle2'>{t('proof_points.' + proofPoint.code + '.criteria')}</Typography>
                            </Card>


                            {proofPoint.documents?.length >= 0 ?
                                <>
                                    <List>
                                        <ListItemButton onClick={() => { downloadProofFile(proofPoint.documents[0].url) }}>
                                            <ListItemAvatar><SaveAltIcon /></ListItemAvatar>

                                            <ListItemText primary={<Box className={'modal-text'}>{t('modal.download_link')}</Box>} />
                                        </ListItemButton>
                                    </List>
                                </> : <>
                                    <List>
                                        <ListItem onClick={() => { downloadProofFile(proofPoint.fileUrl) }}>
                                        </ListItem>
                                    </List>
                                </>}

                        </List>


                    </Box>
                    <DialogContent>
                        <Button variant='text' onClick={sendCloseMessage}>{t('modal.close')}</Button>
                    </DialogContent>

                    <DialogActions>
                        Action
                    </DialogActions>
                    <Box className="mobile-footer">

                        {t('modal.verified_by')}<img src={'ecocheck_small.png'} style={{ pt: 2, mt: 2 }} />
                    </Box>
                </Box>
            </>
        )

    }


    return (
        <>
            {1 == 1 &&
                <Box sx={{ backgroundColor: '#fff', height: 'auto' }}>
                    <Grid container spacing={0}>

                        {(pid && pid !== undefined) ? <RenderDetail proofPoint={proofPoints.find(p => p.code == pid)} /> :
                            <RenderOverview />
                        }

                    </Grid>

                </Box>
            }
            {(isDesktop && 1 == 2) &&
                <>
                    <div className={'outer-container'}>
                        <Box sx={{ width: '100%', typography: 'body2' }}>
                            <div className={'dialog'}>
                                <TabContext value={value}>
                                    <div className="head">
                                        <img src={product.image_url} alt="<%= product.name %>" className="product-image" />
                                        <ButtonBase sx={{ float: 'right', padding: 0.5, borderRadius: 2 }} variant="text" className="head-close" onClick={sendCloseMessage}><CloseIcon /></ButtonBase>

                                        <Box style={{ fontSize: '16pt', fontFamility: "halisr-book", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <span>{product.name}</span>
                                        </Box>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example" orientation='horizontal' variant='scrollable'>
                                            {renderTab('climate')}
                                            {renderTab('nature')}
                                            {renderTab('waste')}
                                            {renderTab('social')}
                                            {renderTab('security')}
                                            {renderTab('all')}
                                        </TabList>
                                    </div>

                                    <Box>


                                    </Box>
                                    <div className="content">
                                        {renderTabPanel('climate')}
                                        {renderTabPanel('nature')}
                                        {renderTabPanel('waste')}
                                        {renderTabPanel('social')}
                                        {renderTabPanel('security')}
                                        {renderTabPanel('all')}
                                    </div>
                                </TabContext>

                                <div className="footer">
                                    <span>
                                        {t('modal.verified_by')}</span><img src={'ecocheck_small.png'} />
                                </div>

                            </div>
                        </Box >
                    </div >
                </>
            }
            {(isMobile && 1 == 2) &&
                <Box className={'mobile-outer-container'}>
                    <Box className={'mobile-container'}>

                        <Box sx={{
                            position: 'sticky',
                            top: 0,
                            flex: 1,
                            zIndex: 1000,
                            backgroundColor: '#fff',
                            justifyContent: 'space-between',
                            textAlign: 'center',
                            flexDirection: 'row',
                            alignItems: 'center',
                            pl: 1,
                            pt: 1,
                            pr: 1,
                            pb: 1
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'

                            }}>

                                <img src={product?.image_url} alt="<%= product.name %>" className="mobile-product-image" />

                                <Box>
                                    <span><b>{product.name}</b></span><br />


                                </Box>
                                <ButtonBase sx={{ float: 'right', padding: 0.5, borderRadius: 2 }} variant="text" className="head-close" onClick={sendCloseMessage}><CloseIcon /></ButtonBase>
                            </Box>
                            <Box fullWidth sx={{ flex: 1, mt: 1, flexDirection: 'row', justifyContent: 'space-around', display: 'inline-flex' }}>
                                {renderChip('climate')}
                                {renderChip('nature')}
                                {renderChip('waste')}
                                {renderChip('social')}
                                {renderChip('security')}
                                {renderChip('all')}
                            </Box>
                        </Box>
                        <List>
                            {proofPoints.filter((p) => p.group === value || value == 'all').map((proofPoint, index) => (
                                <Accordion expanded={proofPoint.code == pid} onClick={() => { if (proofPoint.code == pid) { setPid(null) } else { setPid(proofPoint.code) } }} disableGutters variant='elevation' sx={{ backgroundColor: t('groups.' + proofPoint.group + '.color') }}
                                    key={proofPoint.code} ref={(el) => (accordionRefs.current[proofPoint.code] = el)}>

                                    <AccordionSummary >

                                        <Box sx={{
                                            display: 'flex',
                                            flex: 1,
                                            textAlign: 'left',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <img className="mobile-proofpoint-image" src={proofPoint?.image_url} alt={proofPoint.name} />
                                            <span><b>{t('proof_points.' + proofPoint.code + '.label')}</b></span><br />
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ paddingLeft: 2, marginTop: 0, paddingTop: 0, border: '0px solid #000' }}>
                                        <Box sx={{}}>
                                            <div className="details">

                                                <Card sx={{ p: 1, mb: 1 }}>
                                                    <Box sx={{ m: 1 }} className={'mobile-modal-text'}>
                                                        <span>{t('proof_points.' + proofPoint.code + '.info')}</span>
                                                    </Box>
                                                </Card>

                                                <Card sx={{ p: 1, mb: 1 }}>
                                                    <span>{t('modal.about')} <b>'{t('proof_points.' + proofPoint.code + '.label')}'</b>?</span>
                                                    <Box sx={{ m: 1 }} className={'mobile-modal-text'}><span>{t('proof_points.' + proofPoint.code + '.description')}</span></Box>


                                                </Card>
                                                <Card sx={{ p: 1, mb: 1 }}>
                                                    <span>
                                                        <>{t('modal.requirement')}:</>
                                                    </span>


                                                    <Box sx={{ m: 1 }} className={'mobile-modal-text'}>
                                                        <span>{t('proof_points.' + proofPoint.code + '.criteria')}</span>
                                                    </Box>
                                                </Card>

                                                {proofPoint.documents?.length >= 0 ?
                                                    <>
                                                        <List>
                                                            <ListItemButton onClick={() => { downloadProofFile(proofPoint.documents[0].url) }}>
                                                                <ListItemAvatar><SaveAltIcon /></ListItemAvatar>

                                                                <ListItemText primary={<Box className={'mobile-modal-text'}><span>{t('modal.download_link')}</span></Box>} />
                                                            </ListItemButton>
                                                        </List>
                                                    </> : <>
                                                        <List>
                                                            <ListItem onClick={() => { downloadProofFile(proofPoint.fileUrl) }}>
                                                            </ListItem>
                                                        </List>
                                                    </>}


                                            </div>
                                        </Box>
                                    </AccordionDetails>

                                </Accordion>
                            ))}
                        </List>
                        <div className="mobile-footer">
                            <span>
                                {t('modal.verified_by')}</span><img src={'ecocheck_small.png'} />
                        </div>
                    </Box>
                </Box >
            }
        </>
    )
}
export default Modal