import { Button, List, ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material';
import React, { useState, useEffect } from 'react';
const ChoosePlanForm = ({ company, setCompany }) => {

    const [plans, setPlans] = useState([{ 'name': 'Basic', 'price': 10 }, { 'name': 'Pro', 'price': 20 }, { 'name': 'Enterprise', 'price': 30 }]);

    const [loading, setLoading] = useState(false)

    const { config } = require('../../../config')


    useEffect(() => {
        const fetchPlans = async () => {
            try {
                setLoading(true)
                const result = await fetch(`${config.apiUrl}/stripe/plans`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        //    'Authorization': `${localStorage.getItem('accessToken')}`
                    }
                })
                const data = await result.json()
                setPlans(data.data)
            } catch (error) {
                console.log(error)
            }
            finally {
                setLoading(false)
            };
        }

        fetchPlans();
    }, []);


    const handleSelectPlan = (plan) => {
        setCompany({ ...company, stripe: { ...company.stripe, plan: plan } })
    }

    const handlePayNow = async () => {
        try {
            const result = await fetch(`${config.apiUrl}/stripe/checkout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //    'Authorization': `${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify({ plan: company.stripe.plan })
            })
            const data = await result.json()
            window.location.href = data.url;
        }
        catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            {!loading ? <>
                <List>
                    {plans?.map((plan) => (
                        <ListItemButton key={plan.id} onClick={()=>handleSelectPlan(plan)} sx={{border:company?.stripe?.plan?.id==plan?.id?'1px solid #d0d0d0':'0px'}}>
                            <ListItemText>
                                <h3>{plan.product?.name}</h3>
                                <p>{plan.product?.description}</p>
                                <ul>
                                    {plan.product?.features?.map((feature, index) => {
                                        return <li>{feature.name}</li>
                                    })}
                                </ul>
                            </ListItemText>
                            <ListItemSecondaryAction>
                            <p><h3>{plan.currency} {plan.unit_amount_decimal / 100}</h3> / {plan.recurring?.interval}</p>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    ))}
                </List>
            </> : <p>Loading available plans...</p>}

            <Button variant="contained" color="primary" onClick={() => handlePayNow()}>Pay Now</Button>
        </div>
    );

}

export default ChoosePlanForm;