import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { config } from "../../config";
import Clickable from "./Clickable";
const Widget = () => {
    const { ean } = useParams()
    const [searchParams] = useSearchParams();

    const template = searchParams.get("template"); // "text"    
    const primaryColor = searchParams.get("primarycolor") || '000'  // "text"
    const secondaryColor = searchParams.get("secondarycolor") || 'fff'; // "text"
    const borderRadius = searchParams.get("borderradius"); // "text"

    const [url, setUrl] = useState(); // Make sure you have initialized the user state

    const [proofPoints, setProofPoints] = useState([]); // Make sure you have initialized the user state

    const { apiUrl } = config
    const fetchProofPoints = async () => {
        try {
            const res = await fetch(`${apiUrl}/badges/ean/${ean}`);
            const data = await res.json();
            console.log(data);
            setProofPoints(data.badges);

        } catch (err) {
            setProofPoints([]);
            console.error(err);
        }
    }

    useEffect(() => {
        fetchProofPoints();
        setUrl(`${config.modalUrl}/${ean}/modal/`);
    }, []);

    const style = {
        container: {
            display: 'inline-flex',
            flexDirection: 'row',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#' + primaryColor,
            width: '100%',
            boxSizing: 'border-box',
            fontFamily: 'Arial',
            color: '#' + secondaryColor,
            borderRadius: borderRadius,
            boxShadow: '0 0 10px rgba(0,0,0,0.5)',
            position: 'relative'
        },
        circle: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#' + secondaryColor,
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            border: '1px solid #' + primaryColor,
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#' + primaryColor,
        },
        opp_50: {
            opacity: '50%',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#' + secondaryColor,
            marginRight: '-28px'
        },
        text: {
            marginLeft: '50px'
        }
    }

    return (
        <>
            {proofPoints?.length > 0 ?
                <div style={{display:'inline-flex'}}>
                    {proofPoints.map((proofPoint, index) => {
                        return (
                            <Clickable url={url}>
                                <div key={index}>
                                    <div style={style.circle}>{proofPoint.id}</div>
                                    <div style={style.text}>{proofPoint.name}</div>
                                </div>
                            </Clickable>
                        )

                    })
                    }


                </div>
                :
                <div></div>

            }
        </>
    );
}
export default Widget;